import assert from 'assert';
import { useUpdateWorkspaceMutation } from 'hooks/globalApi';
import { useGetWorkspaceByIdQuery } from 'hooks/internalApi';
import { useGlobalApiContext } from 'hooks/useGlobalApiContext';
import { useInternalApiContext } from 'hooks/useInternalApiContext';
import React, { useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { getResultOrError } from 'utils/errors';
import { useCatchMutationError } from 'utils/urql';
import { useWorkspace } from 'wrappers/WorkspaceProvider';

import { PrimaryButtonV2 } from '@fragment/ui/src/components/Button/PrimaryButtonV2/PrimaryButtonV2';
import { Icon } from '@fragment/ui/src/components/Icon';
import { InlineError } from '@fragment/ui/src/components/InlineError/InlineError';
import { TextInput } from '@fragment/ui/src/components/Input/TextInput';
import { TextInputCopy } from '@fragment/ui/src/components/TextInputCopy/TextInputCopy';

type Params = {
  onWorkspaceUpdate: (result: {
    workspace: { id: string; name: string };
  }) => Promise<void>;
};
type FormData = {
  workspaceName: string;
};

const workspaceNameId = 'workspaceName';

export const UpdateWorkspaceSettingsForm = ({ onWorkspaceUpdate }: Params) => {
  const { workspace: activeWorkspaceFromContext } = useWorkspace();
  assert(!!activeWorkspaceFromContext, 'Workspace is not set.');
  const workspaceId = activeWorkspaceFromContext.id;
  const namespace = useId();
  const apiURLFieldId = `${namespace}-api-url`;
  const OAuthDomainId = `${namespace}-OAuthDomain`;
  const OAuthScope = `${namespace}-OAuthScope`;
  const { context: internalContext } = useInternalApiContext();
  const { context: globalContext } = useGlobalApiContext();

  const variables = useMemo(() => ({ workspaceId }), [workspaceId]);
  const [{ data: workspaceData, error: workspaceError }] =
    useGetWorkspaceByIdQuery({
      variables,
      context: internalContext,
      requestPolicy: 'cache-first',
    });
  const [
    { error: updateWorkspaceError, data: updateWorkspaceData },
    updateWorkspace,
  ] = useCatchMutationError(useUpdateWorkspaceMutation, 'updateWorkspace');

  const result = getResultOrError(workspaceData?.workspace, workspaceError);
  const workspace = result.result;

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors, isValid, isDirty },
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      workspaceName: workspace?.name,
    },
  });

  if (result.isError) {
    return <InlineError>Failed to load workspace</InlineError>;
  }

  const onSubmit = async (data: FormData) => {
    assert(workspace);
    const updateWorkspaceResp = await updateWorkspace(
      {
        workspaceId: workspace.id,
        update: {
          name: data.workspaceName,
        },
      },
      globalContext
    );

    if (updateWorkspaceResp.data?.updateWorkspace.__typename === 'Workspace') {
      await onWorkspaceUpdate({
        workspace: updateWorkspaceResp.data.updateWorkspace,
      });
    }
    reset(data);
  };

  const errorMessages = Object.entries(errors)
    .map(([fieldName, error]) =>
      error.message ? <div key={fieldName}>{error.message}</div> : null
    )
    .filter((ele) => ele !== null);

  return (
    <form
      className="flex flex-col space-y-f2 h-full text-clip"
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextInput
        padding="p-0"
        flexContainer="space-y-f0"
        size="md"
        width="w-full"
        label="Name"
        placeholder="Workspace Name"
        hasError={!!errors.workspaceName}
        id={workspaceNameId}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(workspaceNameId, {
          required: {
            value: true,
            message: 'Enter a Workspace name',
          },
          maxLength: {
            value: 128,
            message: 'Workspace names should be shorter than 128 characters',
          },
        })}
      />

      <TextInputCopy
        border={false}
        padding="p-0"
        id={apiURLFieldId}
        size="md"
        value={activeWorkspaceFromContext.pod.customerApiUrl}
        label="API URL"
        width="w-full"
      />

      <TextInputCopy
        border={false}
        padding="p-0"
        id={OAuthDomainId}
        size="md"
        value={`https://${activeWorkspaceFromContext.pod.cognitoAuthDomain}/oauth2/token`}
        label="OAuth URL"
        width="w-full"
      />

      <TextInputCopy
        border={false}
        padding="p-0"
        id={OAuthScope}
        size="md"
        value={`${activeWorkspaceFromContext.pod.customerApiOAuthScope}`}
        label="OAuth Scope"
        width="w-full"
      />

      <div className="pt-f2">
        {/* TODO: Label shouldn't be struck out when in Saving state */}
        <PrimaryButtonV2
          disabled={isSubmitting || !isValid || !isDirty}
          type="submit"
        >
          {isSubmitting ? 'Saving...' : 'Save Changes'}
        </PrimaryButtonV2>
        {!isDirty &&
        !isSubmitting &&
        (updateWorkspaceData || updateWorkspaceError) ? (
          <div className="pt-f2">
            {updateWorkspaceData?.updateWorkspace.__typename ===
              'Workspace' && (
              <div className="flex flex-row items-center">
                {/* TODO: This icon needs to be a SVG */}
                <Icon type="success" backgroundColor="black" />
                &nbsp;Workspace updated
              </div>
            )}
            {updateWorkspaceError && (
              <div className="flex flex-row items-center">
                <Icon type="error" backgroundColor="orange" />
                &nbsp;
                <div className="flex flex-col">
                  {errorMessages.length > 0 && errorMessages}
                  {!errorMessages.length && 'Workspace update failed'}
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </form>
  );
};
