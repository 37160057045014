import type { FC } from 'react';
import React from 'react';

import { type BaseSvgProps, colorToHexCode, sizeToSvgWidth } from '../../utils';

export const Income: FC<BaseSvgProps> = ({
  width = sizeToSvgWidth.sm,
  backgroundColorHexCode = colorToHexCode['income-blue'],
  primaryColorHexCode = colorToHexCode.white,
  includeSecondaryMark = false,
  'data-testid': testId,
}) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
  >
    <circle cx="6" cy="6" r="6" fill={backgroundColorHexCode} />
    <path
      d="M3.82812 9.49795H8.17212V8.60995H6.50412V3.38995H8.17212V2.50195H3.82812V3.38995H5.48412V8.60995H3.82812V9.49795Z"
      fill={primaryColorHexCode}
    />
    {includeSecondaryMark && (
      <path
        d="M12 1.5C12 2.32843 11.3284 3 10.5 3C9.67157 3 9 2.32843 9 1.5C9 0.671573 9.67157 0 10.5 0C11.3284 0 12 0.671573 12 1.5Z"
        fill="#0D0D0D"
      />
    )}
  </svg>
);
