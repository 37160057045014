import type { FC } from 'react';
import React from 'react';

import { type BaseSvgProps, colorToHexCode, sizeToSvgWidth } from '../../utils';

export const Asset: FC<BaseSvgProps> = ({
  width = sizeToSvgWidth.sm,
  backgroundColorHexCode = colorToHexCode['asset-green'],
  primaryColorHexCode = colorToHexCode.white,
  includeSecondaryMark = false,
  'data-testid': testId,
}) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
  >
    <circle cx="6" cy="6" r="6" fill={backgroundColorHexCode} />
    <path
      d="M3.98999 9.19595L4.64999 7.29995H7.32599L7.96199 9.19595H9.11399L6.66599 2.19995H5.38199L2.88599 9.19595H3.98999ZM4.94999 6.41195L6.00599 3.36395L7.02599 6.41195H4.94999Z"
      fill={primaryColorHexCode}
    />
    {includeSecondaryMark && (
      <path
        d="M12 1.5C12 2.32843 11.3284 3 10.5 3C9.67157 3 9 2.32843 9 1.5C9 0.671573 9.67157 0 10.5 0C11.3284 0 12 0.671573 12 1.5Z"
        fill="#0D0D0D"
      />
    )}
  </svg>
);
