import type { PropsWithChildren } from 'react';

import React, { useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Icon } from '@fragment/ui/src/components/Icon';

export const ErrorFallback = (): JSX.Element => (
  <div className="p-8 flex flex-col items-start gap-f8 text-xl">
    <span>Page load error</span>
    <div className="flex flex-col items-start">
      <a href={window.location.href}>
        Reload <Icon type="rotate" size="xl" primaryColor="gray" />
      </a>
      <a
        className="text-main-500"
        href={
          // It's possible to end up in a loop here, e.g. if the user directly navigates
          // to a URL containing a nonexistent workspace (dashboard.fragment.dev/w/garbage/...)
          // This at least links them back to the main page in such a case.
          document.referrer !== window.location.href ? document.referrer : '/'
        }
      >
        Back to Dashboard <Icon type="right" size="xl" primaryColor="gray" />
      </a>
    </div>
  </div>
);

export const GlobalErrorBoundary = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const onError = useCallback((error: Error) => {
    console.error(error);
  }, []);

  return (
    <ErrorBoundary onError={onError} FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundary>
  );
};
