import clsx from 'clsx';
import { FullPage } from 'components/Layout/FullPageLayout';
import React, { useCallback } from 'react';
import { FragmentEnv } from 'utils/env';
import { useAuth0 } from '@auth0/auth0-react';

import { Button } from '@fragment/ui/src/components/Button/BaseButton/Button';
import { ErrorText } from '@fragment/ui/src/components/ErrorText';
import { List, ListItem, Marker } from '@fragment/ui/src/components/List';
import { LoadingText } from '@fragment/ui/src/components/LoadingText';
import { Logo } from '@fragment/ui/src/components/Logo/Logo';

type LoadingProps = {
  loading?: boolean;
};

type Props =
  | (LoadingProps & {
      message?: string;
      errorMessage?: never;
    })
  | (LoadingProps & {
      message?: never;
      errorMessage?: string;
    });

export const LoginView = ({
  loading = false,
  errorMessage,
  message,
}: Props) => {
  const { loginWithRedirect } = useAuth0();
  const handler = useCallback(
    () =>
      loginWithRedirect({
        authorizationParams: {
          audience: FragmentEnv.auth0.globalApiAudience,
          connection: 'google-oauth2',
          scope: 'openid email profile offline_access',
        },
      }),
    [loginWithRedirect]
  );

  const markerClass = `text-main-500 ${
    loading ? 'group-hover:text-main-500' : 'group-hover:text-main'
  }` as const;
  const textColorClass = loading ? 'text-main-500' : 'text-main';
  const shouldRenderMessageContainer = !!message || !!errorMessage;

  return (
    <FullPage.Layout>
      <Logo />
      <div className="mt-[4em]">
        {loading && (
          <span className={clsx('text-xl', textColorClass)}>
            <LoadingText text="Finding Account" />
          </span>
        )}
        {!loading && (
          <List
            type="ordered"
            fontFamily="font-FragmentMono"
            fontSize="text-xl"
          >
            <div className="group">
              <Button
                type="button"
                onClick={handler}
                display="flex"
                disabled={loading}
                textAlign="text-left"
              >
                <Marker type="numeric" ordinal={0} color="text-gray" />

                <span className={clsx('pl-[1ch]', textColorClass)}>
                  Login with Google
                </span>
              </Button>
            </div>
            <ListItem group>
              <Marker type="numeric" ordinal={1} color="text-gray" />
              <a
                href="https://fragment.dev/get-access"
                className={clsx('pl-[1ch]', textColorClass)}
              >
                Get Access
              </a>
            </ListItem>
          </List>
        )}
        {shouldRenderMessageContainer && (
          <div className="mt-[4em]">
            {message && <span className="text-md">{message}</span>}
            {errorMessage && (
              <ErrorText fontSize="text-xl">{errorMessage}</ErrorText>
            )}
          </div>
        )}
      </div>
      <FullPage.Footer>
        <div className="w-full">
          <span className="text-md">
            By logging in, you agree to our{' '}
            <a
              href="https://fragment.dev/legal"
              className="text-main hover:text-main transition-colors underline decoration-main-500 hover:decoration-main"
            >
              terms of use and privacy policy
            </a>
            .
          </span>
        </div>
      </FullPage.Footer>
    </FullPage.Layout>
  );
};
