import assert from 'assert';
import clsx from 'clsx';
import React, { useState } from 'react';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';

import { Icon } from '../Icon';
import { InlineCopy } from '../InlineCopy';
import { type TextSizeType } from '../utils';
import { PreTag } from './PreTag';

const codeblockLanguages = [
  'ruby',
  'go',
  'graphql',
  'json',
  'python',
  'bash',
  'plaintext',
  'yaml',
  'typescript',
  'javascript',
] as const;

export type CodeBlockLanguage = (typeof codeblockLanguages)[number];

export type CodeBlockProps = {
  title?: React.ReactNode;
  language: CodeBlockLanguage;
  showLineNumbers?: boolean;
  children: string;
  defaultExpanded?: boolean;
  expandable?: boolean;
  disableCopyInteraction?: boolean;
  textSize: TextSizeType;
};

export const CodeBlock = ({
  title,
  language,
  showLineNumbers = false,
  textSize,
  expandable = false,
  defaultExpanded = false,
  disableCopyInteraction = false,
  children,
}: CodeBlockProps) => {
  assert(
    codeblockLanguages.includes(language),
    `Language ${language} is not supported`
  );
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const hasTitle = !!title;
  const headerContainerClasses = clsx(
    'flex flex-row',
    'bg-negative',
    'justify-between',
    hasTitle && ['w-full', 'text-main-500', textSize]
  );

  const TitleComponent =
    typeof title === 'string' ? (
      <span className="text-main-500">{`${title} `}</span>
    ) : (
      title
    );

  const titleContainerClasses = clsx(
    hasTitle && ['px-f2 py-f2', 'flex flex-row flex-auto'],
    hasTitle && typeof title === 'string' && ['truncate']
  );
  const inlineCopyContainerClasses = clsx(
    ['px-f2', 'py-f2'],
    ['invisible', 'group-one-hover:visible'],
    ['min-w-fit'],
    !hasTitle && ['bg-codeblock-copy-texture'],
    disableCopyInteraction && ['hidden']
  );

  const codeClasses = clsx([
    expandable && !isExpanded && 'max-h-f20',
    'flex min-h-f0 w-full',
  ]);
  const code = (
    <div className={codeClasses}>
      <SyntaxHighlighter
        PreTag={PreTag}
        language={language}
        showLineNumbers={showLineNumbers}
        lineNumberStyle={{
          textAlign: 'left',
          minWidth: '3em',
          paddingRight: '0em',
        }}
        // This is passed to Pre as props.
        // wrapLongLines
        hasTitle={hasTitle}
        textSize={textSize || 'sm'}
      >
        {children}
      </SyntaxHighlighter>
    </div>
  );

  const onExpand = () => {
    setIsExpanded(!isExpanded);
  };

  if (hasTitle && expandable) {
    return (
      <div className="relative flex min-w-f0">
        <div
          className="flex group-one bg-negative flex-col min-w-f0 w-full"
          data-test-name="codeblock"
        >
          <div className={headerContainerClasses}>
            <div
              data-test-role="title"
              className={clsx(
                titleContainerClasses,
                'hover:cursor-pointer group-two'
              )}
              onClick={onExpand}
              role="button"
              tabIndex={0}
              onKeyDown={(event) => event.key === 'Enter' && onExpand}
            >
              <div className="flex-initial">{TitleComponent}</div>
              <div className="pl-f2 group-two-hover:text-main group-one-hover:block transition-colors">
                {/* doing some nasty here for font sizes because Text is annoying */}
                <div className="hidden laptop:block">
                  <Icon type={isExpanded ? 'up' : 'down'} size="md" />
                </div>
                <div className="laptop:hidden">
                  <Icon type={isExpanded ? 'up' : 'down'} size="sm" />
                </div>
              </div>
            </div>
            {isExpanded ? (
              <div
                className={inlineCopyContainerClasses}
                aria-hidden="true"
                data-testid="code-block-copy-button"
              >
                <InlineCopy
                  onCopyChildren="Copied"
                  onCopyError={() => {}}
                  textToCopy={children as string}
                >
                  Copy
                </InlineCopy>
              </div>
            ) : (
              <div
                className={clsx(
                  'py-f2 px-[1.25em] hover:cursor-pointer hidden group-one-hover:block',
                  textSize
                )}
              >
                <span
                  onClick={onExpand}
                  className="text-main-500 hover:text-main"
                >
                  Show
                </span>
              </div>
            )}
          </div>
          {code}
        </div>
        {!isExpanded && (
          <div className="absolute w-full left-f0 bottom-f0 min-h-f20 bg-gradient-to-b from-transparent to-negative">
            {' '}
            &nbsp;
          </div>
        )}
      </div>
    );
  }
  if (hasTitle && !expandable) {
    return (
      <div
        className="inline-flex flex-col group-one min-h-f0 w-full"
        data-test-name="codeblock"
      >
        <div className={headerContainerClasses}>
          <div className={titleContainerClasses} data-test-role="title">
            <span className="text-main-500">{title}</span>
          </div>
          <div
            className={inlineCopyContainerClasses}
            aria-hidden="true"
            data-testid="code-block-copy-button"
          >
            <InlineCopy
              onCopyChildren="Copied"
              onCopyError={() => {}}
              textToCopy={children as string}
            >
              Copy
            </InlineCopy>
          </div>
        </div>
        {code}
      </div>
    );
  }
  return (
    <div className="group-one" data-test-name="codeblock">
      <div className={headerContainerClasses}>
        <div className="min-w-[75%] grow">{code}</div>
        <div
          className={inlineCopyContainerClasses}
          aria-hidden="true"
          data-testid="code-block-copy-button"
        >
          <InlineCopy
            onCopyChildren="Copied"
            onCopyError={() => {}}
            textToCopy={children as string}
          >
            Copy
          </InlineCopy>
        </div>
      </div>
    </div>
  );
};
