import { useGetWorkspaceInvoicesQuery } from 'hooks/internalApi';
import { useInternalApiContext } from 'hooks/useInternalApiContext';
import React, { useCallback, useMemo } from 'react';
import { getResultOrError } from 'utils/errors';
import { useWorkspaceId } from 'wrappers/WorkspaceProvider';

import { InvoicesTable } from '../../../billing/InvoicesTable';
import { TableLoadError } from '../../../TableLoadError/TableLoadError';

export const BillingForm = () => {
  const workspaceId = useWorkspaceId();

  const { context } = useInternalApiContext();
  const variables = useMemo(() => ({ workspaceId }), [workspaceId]);

  const [{ data, error, fetching }, reExecuteQuery] =
    useGetWorkspaceInvoicesQuery({
      variables,
      context,
    });

  const refreshTable = useCallback(
    () => reExecuteQuery({ requestPolicy: 'network-only' }),
    [reExecuteQuery]
  );

  const { isError: isWorkspaceError, result: workspace } = getResultOrError(
    data?.workspace,
    error
  );
  const { isError: isInvoicesError, result: invoices } = getResultOrError(
    workspace?.invoices,
    error
  );

  return (
    <div className="flex flex-col space-y-f4">
      <div className="flex justify-between">
        <span>Billing</span>
      </div>
      {isWorkspaceError || isInvoicesError ? (
        <TableLoadError
          entityColumnName="Invoice"
          rightColumnName="Status"
          {...{ error, retry: refreshTable, fetching }}
        />
      ) : (
        <InvoicesTable textSize="md" data={invoices.nodes} />
      )}
    </div>
  );
};
