import clsx from 'clsx';
import { ToastManagerContext } from 'components/ToastManager';
import {
  type CreateApiClientMutation,
  useCreateApiClientMutation,
} from 'hooks/internalApi';
import { useContextSafely } from 'hooks/useContextSafely';
import { useInternalApiContext } from 'hooks/useInternalApiContext';
import React, { useCallback, useState } from 'react';
import { getResultOrError } from 'utils/errors';
import { useWorkspaceId } from 'wrappers/WorkspaceProvider';

import { FormButtons } from '@fragment/ui/src/components/Button/ButtonGroup/FormButtons';
import { TextInput } from '@fragment/ui/src/components/Input/TextInput';
import { Toast } from '@fragment/ui/src/components/Toast';

export type ApiClient = CreateApiClientMutation['createApiClient'] & {
  __typename: 'CreateApiClientResult';
};

type Props = {
  onCreate: (result: ApiClient) => void;
  onCancel: VoidFunction;
};

export const ApiClientForm = ({ onCreate, onCancel }: Props) => {
  const { showToast } = useContextSafely(ToastManagerContext);
  const { context } = useInternalApiContext();
  const [{ fetching }, createClient] = useCreateApiClientMutation();
  const [clientName, setName] = useState('');

  const workspaceId = useWorkspaceId();
  const handleCreate = useCallback(async () => {
    const { data, error } = await createClient(
      {
        workspaceId,
        clientName,
      },
      context
    );
    const res = getResultOrError(data?.createApiClient, error);

    if (res.isError) {
      showToast(<Toast message="Error Creating API Key" type="error" />);
    } else {
      onCreate(res.result);
    }
  }, [createClient, workspaceId, clientName, context, onCreate, showToast]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleCreate();
      }}
    >
      <div className={clsx('space-y-f2', 'max-w-full ')}>
        <span>New API Client</span>
        <div className="space-y-f6 w-full">
          <TextInput
            flexContainer="space-y-f0"
            id="new-client-name"
            label="Name"
            placeholder="Client Name"
            size="md"
            value={clientName}
            onChange={(e) => setName(e.target.value)}
            padding="py-f0 px-0"
            width="w-full"
          />
        </div>
        <FormButtons
          type="tertiary"
          onCancel={onCancel}
          cancelButtonLabel="Cancel"
          submitButtonLabel={fetching ? 'Creating...' : 'Create'}
          disabled={!clientName.trim()}
        />
      </div>
    </form>
  );
};
