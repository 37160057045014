import type { FC } from 'react';
import React from 'react';

import { type BaseSvgProps, colorToHexCode, sizeToSvgWidth } from '../../utils';

export const ExternalAccount: FC<BaseSvgProps> = ({
  width = sizeToSvgWidth.sm,
  primaryColorHexCode = colorToHexCode.keyline,
  'data-testid': testId,
}) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    data-testid={testId}
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.34009" cy="6" r="6" fill={primaryColorHexCode} />
  </svg>
);
