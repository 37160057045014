import './index.css';

import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';


import { App } from './App';
import { reportWebVitals } from './reportWebVitals';
import { FragmentEnv } from './utils/env';

// NOTE: This is a workaround for SyntaxHighlighter not working reliably in the
// EmbedCodeDialog component. It seems to have the effect of warming some
// sort of internal cache that avoids a race condition within
// SyntaxHighlighter.
// See: https://github.com/react-syntax-highlighter/react-syntax-highlighter/issues/513
createRoot(document.createElement('div')).render(
  // eslint-disable-next-line react/no-children-prop
  <SyntaxHighlighter language="" children="" />
);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <PostHogProvider
    apiKey={FragmentEnv.posthog.apiKey}
    options={{ api_host: `https://${FragmentEnv.posthog.domain}` }}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </PostHogProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// @ts-expect-error "we import restify-errors which assumes process.env exists"
window.process = {
  env: {},
};
