import type { FC } from 'react';
import { FullPage } from 'components/Layout/FullPageLayout';
import React from 'react';

import { LoadingText } from '@fragment/ui/src/components/LoadingText';

export const TopLevelLoader: FC = () => {
  const wrap = (child: React.ReactNode) => (
    <span
      style={{
        fontFeatureSettings: `'ss01' on`,
        fontVariantLigatures: 'no-contextual',
      }}
    >
      {child}
    </span>
  );

  return (
    <FullPage.Layout>
      {wrap(
        <span className="text-xl">
          <LoadingText text="FRAGMENT" color="text-main" />
        </span>
      )}
    </FullPage.Layout>
  );
};
