import type { FC } from 'react';
import React from 'react';

import type { BaseSvgProps } from '../../utils';
import { sizeToSvgWidth } from '../../utils';

export const StripeLink: FC<BaseSvgProps> = ({
  width = sizeToSvgWidth.sm,
  'data-testid': testId = 'linkbadge',
}) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    data-testid={testId}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="12"
      height="12"
      transform="translate(0.782227)"
      fill="#635BFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.2681 4.53407C6.2681 4.22335 6.52305 4.10385 6.9453 4.10385C7.55079 4.10385 8.31563 4.28709 8.92112 4.61374V2.74148C8.25986 2.47857 7.60656 2.375 6.9453 2.375C5.32799 2.375 4.25244 3.21951 4.25244 4.62967C4.25244 6.82857 7.27991 6.47802 7.27991 7.4261C7.27991 7.79258 6.96123 7.91209 6.51508 7.91209C5.85381 7.91209 5.00931 7.64121 4.34008 7.27473V9.17088C5.08101 9.48956 5.82991 9.625 6.51508 9.625C8.17222 9.625 9.31151 8.80439 9.31151 7.3783C9.30354 5.00412 6.2681 5.42637 6.2681 4.53407Z"
      fill="white"
    />
  </svg>
);
