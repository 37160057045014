import React from 'react';

import { type BaseSvgProps, colorToHexCode, sizeToSvgWidth } from '../../utils';

export const LiabilityLine = ({
  'data-testid': testId,
  width = sizeToSvgWidth.sm,
  primaryColorHexCode = colorToHexCode['liability-red'],
  backgroundColorHexCode = colorToHexCode.white,
}: BaseSvgProps) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    data-testid={testId}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.573975"
      y="0.5"
      width="12"
      height="12"
      rx="6"
      fill={backgroundColorHexCode}
    />
    <path
      d="M0.573975 6.5H6.57397V12.5C3.26027 12.5 0.573975 9.81371 0.573975 6.5Z"
      fill={primaryColorHexCode}
    />
  </svg>
);
