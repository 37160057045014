import { DeleteDataExportForm } from 'components/dataExport/DeleteDataExportForm';
import React, { type FC } from 'react';

import { TextInputCopy } from '@fragment/ui/src/components/TextInputCopy/TextInputCopy';

import type { DataExportRow } from './DataExportsList';

type DataExportDetailProps = {
  dataExport: DataExportRow;
  onConfirmedDelete: () => void;
};
export const DataExportDetail: FC<DataExportDetailProps> = ({
  dataExport,
  onConfirmedDelete,
}) => {
  let url = `s3.console.aws.amazon.com/s3/buckets/${dataExport.exportBucketName}?region=${dataExport.exportBucketRegion}`;
  if (dataExport.exportBucketKey) {
    url += `&prefix=${dataExport.exportBucketKey}/`;
  }
  return (
    <div className="space-y-f2">
      <span>{dataExport.id}</span>
      <div>
        <TextInputCopy
          size="md"
          padding="p-f0"
          id="export-url"
          label="Export URL"
          value={url}
        />
      </div>
      <DeleteDataExportForm
        dataExport={dataExport}
        onDelete={onConfirmedDelete}
      />
    </div>
  );
};
