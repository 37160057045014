import assert from 'assert';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import dedent from 'ts-dedent';
import { useWorkspace } from 'wrappers/WorkspaceProvider';

import type { FormButtonsProps } from '@fragment/ui/src/components/Button/ButtonGroup/FormButtons';
import { FormButtons } from '@fragment/ui/src/components/Button/ButtonGroup/FormButtons';
import { CodeBlock } from '@fragment/ui/src/components/CodeBlock/CodeBlock';
import { TextInput } from '@fragment/ui/src/components/Input/TextInput';

import { TestConnectionButton } from './TestConnectionButton';

type Props = {
  onPageSubmit: (formValues: Record<string, string>) => void;
  formValues: Record<string, string>;
  formButtonsProps: Omit<FormButtonsProps, 'onSubmit'>;
};

type FormData = {
  bucketName: string;
  bucketRegion: string;
};

export const NewDataExportFormAWSPage = ({
  onPageSubmit,
  formValues,
  formButtonsProps,
}: Props) => {
  const { workspace } = useWorkspace();
  assert(workspace);

  const {
    getValues,
    handleSubmit,
    register,
    formState: { isValid },
    watch,
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      bucketName: formValues.bucketName,
      bucketRegion: formValues.bucketRegion,
    },
  });

  // Add a watch to the form to update the codeblock when the form values change
  useEffect(() => {
    watch();
  }, [watch]);

  const onSubmit = useCallback(
    ({ bucketName, bucketRegion }: FormData) => {
      onPageSubmit({ bucketName, bucketRegion });
    },
    [onPageSubmit]
  );

  return (
    <form className="space-y-f1">
      <div className="space-y-f2">
        <span>Setup your AWS account</span>
        <div className="space-y-f2">
          <TextInput
            flexContainer="space-y-f0"
            width="w-full"
            size="md"
            padding="p-f0"
            label="S3 Bucket Name"
            data-testid="s3-bucket-name"
            placeholder="bucket-name"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('bucketName', { required: true })}
          />
          <TextInput
            flexContainer="space-y-f0"
            width="w-full"
            size="md"
            padding="p-f0"
            label="S3 Bucket Region"
            data-testid="s3-bucket-region"
            placeholder="bucket-region"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('bucketRegion', { required: true })}
          />
        </div>
        <div className="space-y-f1">
          <span>Apply this policy to your S3 bucket</span>
          <CodeBlock
            language="json"
            title="S3 Bucket Policy"
            textSize="md"
          >
            {dedent`{
              "Version": "2012-10-17",
              "Statement": [
                {
                  "Sid": "PutObjects",
                  "Effect": "Allow",
                  "Principal": {
                    "AWS": "arn:aws:iam::${workspace.pod.awsAccountId}:root"
                  },
                  "Action": "s3:PutObject",
                  "Resource": "arn:aws:s3:::${
                    getValues('bucketName') || 'YOUR_BUCKET_NAME'
                  }/*"
                }
              ]
            }`}
          </CodeBlock>
          <TestConnectionButton
            bucketName={getValues('bucketName')}
            bucketRegion={getValues('bucketRegion')}
          />
        </div>
      </div>
      <FormButtons
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...formButtonsProps}
        disabled={!isValid}
        onSubmit={handleSubmit(onSubmit)}
      />
    </form>
  );
};
