import type { FC } from 'react';
import React from 'react';

import type { BaseSvgProps } from '../../utils';
import { colorToHexCode, sizeToSvgWidth } from '../../utils';

export const CustomSyncLink: FC<BaseSvgProps> = ({
  width = sizeToSvgWidth.sm,
  backgroundColorHexCode = colorToHexCode.keyline,
  primaryColorHexCode = colorToHexCode.black,
  'data-testid': testId = 'linkbadge',
}) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    data-testid={testId}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="12"
      height="12"
      transform="translate(0.340088)"
      fill="#CCCCCC"
    />
    <circle cx="6.34009" cy="6" r="6" fill={backgroundColorHexCode} />
    <path
      d="M6.95894 4.74076L5.05094 6.64876L5.66294 7.26076L7.57094 5.35276L6.95894 4.74076ZM3.61094 8.70076C4.37894 9.46876 5.51894 9.46876 6.29894 8.70076L7.18694 7.81276L6.57494 7.20076L5.74694 8.02876C5.26694 8.50876 4.70294 8.56876 4.22294 8.08876C3.75494 7.62076 3.81494 7.05676 4.29494 6.57676L5.12294 5.74876L4.51094 5.13676L3.70694 5.94076C2.93894 6.70876 2.85494 7.93276 3.61094 8.70076ZM5.45894 4.18876L6.07094 4.80076L6.89894 3.97276C7.36694 3.49276 7.94294 3.43276 8.41094 3.90076C8.87894 4.36876 8.81894 4.94476 8.35094 5.42476L7.51094 6.25276L8.12294 6.86476L9.02294 5.97676C9.79094 5.20876 9.77894 4.05676 9.02294 3.28876C8.25494 2.53276 7.11494 2.53276 6.34694 3.30076L5.45894 4.18876Z"
      fill="#0D0D0D"
    />
  </svg>
);
