import type { InvoiceStatus } from 'hooks/internalApi';
import React from 'react';

import { Amount } from '@fragment/ui/src/components/Amount/Amount';
import { Table } from '@fragment/ui/src/components/Table';
import type { TextSizeType } from '@fragment/ui/src/components/utils';

export type InvoicesRow = {
  id: string;
  amount: string;
  billingPeriod: string;
  status: InvoiceStatus;
};

const statusDisplayMap: Record<InvoiceStatus, string> = {
  paid: 'Paid',
  unpaid: 'Unpaid',
  void: 'Void',
};

const schema = [
  {
    title: 'Invoice',
    key: 'billingPeriod',
  },
  {
    title: 'Amount',
    justify: 'right',
    format: (row: InvoicesRow) => (
      <span>
        <Amount
          amount={row.amount}
          currency={{ code: 'USD', precision: 2, name: 'USD' }}
        />
      </span>
    ),
  },
  {
    title: 'Status',
    justify: 'right',
    format: (row: InvoicesRow) => <span>{statusDisplayMap[row.status]}</span>,
  },
] as const;

const emptyValue = {
  Invoice: 'No invoices yet',
  Amount: 'N/A',
  Status: 'N/A',
};

type Props = {
  data: InvoicesRow[];
  textSize?: TextSizeType;
};

export const InvoicesTable = ({ data, textSize = 'sm' }: Props) => (
  <Table {...{ schema, data, emptyValue, textSize }} />
);
