import type { FC } from 'react';
import React from 'react';

import type { BaseSvgProps } from '../../utils';
import { sizeToSvgWidth } from '../../utils';

export const UnitLink: FC<BaseSvgProps> = ({
  width = sizeToSvgWidth.sm,
  'data-testid': testId = 'linkbadge',
}) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    data-testid={testId}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="12"
      height="12"
      transform="translate(0.340088)"
      fill="#0d0d0d"
    />
    <path
      d="M7.53035 8.09173H5.9277C5.2441 8.09173 4.98965 7.53674 4.98965 6.77363V2.79506H3.14014V6.7771C3.14014 8.48716 4.44277 9.77751 6.17075 9.77751H9.38366V2.79506H7.53035V8.09173Z"
      fill="#89E68E"
    />
  </svg>
);
