import type { FC } from 'react';
import React from 'react';

import type { BaseSvgProps } from '../../utils';
import { colorToHexCode } from '../../utils';

export const Hamburger: FC<BaseSvgProps> = ({
  'data-testid': dataTestId,
  width = '12', // sm
  primaryColorHexCode = colorToHexCode.gray,
}) => (
  <svg
    data-testid={dataTestId}
    width={width}
    height={width}
    className="shrink-0"
    viewBox="0 0 7 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.596 1.3H6.056V0.235999H0.596V1.3ZM0.596 4.604H6.056V3.54H0.596V4.604ZM0.596 7.908H6.056V6.844H0.596V7.908Z"
      fill={primaryColorHexCode}
    />
  </svg>
);
