import React from 'react';

import { type BaseSvgProps, colorToHexCode, sizeToSvgWidth } from '../../utils';

export const ReconciledTx = ({
  width = sizeToSvgWidth.sm,
  'data-testid': testId,
  backgroundColorHexCode = colorToHexCode.white,
  primaryColorHexCode = colorToHexCode.keyline,
}: BaseSvgProps) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    data-testid={testId}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.573975"
      y="0.5"
      width="12"
      height="12"
      rx="6"
      fill={backgroundColorHexCode}
    />
    <rect
      x="3.57397"
      y="3.5"
      width="6"
      height="6"
      rx="3"
      fill={primaryColorHexCode}
    />
  </svg>
);
