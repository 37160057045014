import assert from 'assert';
import { useToast } from 'components/ToastManager';
import {
  useGetWorkspaceInviteByIdQuery,
  useGetWorkspaceMembersQuery as useGetWorkspaceMembersQueryGlobal,
  useToggleLinkSharingMutation as useGlobalToggleLinkSharingMutation,
} from 'hooks/globalApi';
import { useGlobalApiContext } from 'hooks/useGlobalApiContext';
import React, { useCallback, useMemo, useState } from 'react';
import { getResultOrError } from 'utils/errors';

import { TertiaryButton } from '@fragment/ui/src/components/Button/TertiaryButton/TertiaryButton';
import { Icon } from '@fragment/ui/src/components/Icon';
import { InlineCopy } from '@fragment/ui/src/components/InlineCopy';
import { Table } from '@fragment/ui/src/components/Table';
import { Toast } from '@fragment/ui/src/components/Toast';
import { Toggle } from '@fragment/ui/src/components/Toggle/Toggle';

import type { MembersListRow } from '../../../settings/MembersList';
import { formatDate } from '../../../../utils/date';
import { useWorkspaceId } from '../../../../wrappers/WorkspaceProvider';
import { MemberAccessRevoker } from '../../../settings/MemberAccessRevoker';
import { TableLoadError } from '../../../TableLoadError/TableLoadError';

const schema = [
  {
    title: 'Email',
    key: 'email',
  },
  {
    title: 'Date Added',
    format: (value: MembersListRow) => <span>{formatDate(value.created)}</span>,
    justify: 'right',
  },
] as const;
const MembersListTable = ({
  workspaceId,
  onRowClick,
}: {
  workspaceId: string;
  onRowClick: (row: MembersListRow) => void;
}) => {
  const { context } = useGlobalApiContext();
  const variables = useMemo(() => ({ workspaceId }), [workspaceId]);
  const [{ data, error, fetching }, reExecuteQuery] =
    useGetWorkspaceMembersQueryGlobal({
      variables,
      context,
    });
  const { isError: isWorkspaceError, result: workspace } = getResultOrError(
    data?.workspace,
    error
  );
  const { isError: isMembersError, result: members } = getResultOrError(
    workspace?.members,
    error
  );

  const rowData = isMembersError
    ? []
    : members.nodes.flatMap((member) => {
        if (member?.user.__typename !== 'User' || !member.user.email) {
          return [];
        }
        return [
          {
            id: member.user.id,
            email: member.user.email,
            created: member.created,
          },
        ];
      });

  const refreshTable = useCallback(
    () => reExecuteQuery({ requestPolicy: 'network-only' }),
    [reExecuteQuery]
  );
  if (isWorkspaceError || isMembersError) {
    return (
      <TableLoadError
        entityColumnName="Email"
        rightColumnName="Date Added"
        notFoundErrorCode="member_not_found"
        {...{ error, retry: refreshTable, fetching }}
      />
    );
  }

  return (
    <Table
      textSize="md"
      data={rowData}
      schema={schema}
      onRowClick={onRowClick}
    />
  );
};

export const MembersList = () => {
  const workspaceId = useWorkspaceId();
  const { context, token } = useGlobalApiContext();
  const variables = useMemo(() => ({ workspaceId }), [workspaceId]);
  const [{ data: workspaceData }] = useGetWorkspaceInviteByIdQuery({
    variables,
    context,
    pause: !token,
  });
  assert(workspaceData?.workspace.__typename === 'Workspace');
  const [clickedMember, setClickedMember] = useState<MembersListRow>();
  const { inviteCode, inviteEnabled } = workspaceData.workspace;
  const mutation = useGlobalToggleLinkSharingMutation();

  // Initial state setup
  const [isInviteEnabled, setIsInviteEnabled] = useState(inviteEnabled);
  const [workspaceInviteCode, setWorkspaceInviteCode] = useState(inviteCode);
  const [isMutationError, setIsMutationError] = useState(false);
  const [{ fetching }, toggleLinkSharing] = mutation;

  const inviteCodeComponent = useMemo(() => {
    if (isInviteEnabled) {
      if (fetching || isMutationError) {
        return null;
      }
      return (
        <div className="w-f20 bg-main-200 truncate">
          <InlineCopy
            onCopyChildren="Copied"
            onCopyError={() => {}}
            textToCopy={`${window.location.origin}/w/${encodeURIComponent(
              workspaceId
            )}/invite/${encodeURIComponent(workspaceInviteCode ?? '')}`}
          >
            {`${window.location.origin}/w/${encodeURIComponent(
              workspaceId
            )}/invite/${encodeURIComponent(workspaceInviteCode ?? '')}`}
          </InlineCopy>
        </div>
      );
    }
    return null;
  }, [
    isInviteEnabled,
    fetching,
    isMutationError,
    workspaceInviteCode,
    workspaceId,
  ]);

  let loadingOrDefaultText = 'Off';
  if (fetching) {
    loadingOrDefaultText = '...';
  }

  const { showToast } = useToast();
  const onChange = useCallback(
    async (enable: boolean) => {
      const oldIsInviteEnabled = isInviteEnabled;
      setIsInviteEnabled(enable);
      const response = await toggleLinkSharing(
        {
          workspaceId,
          enable,
        },
        context
      );
      const { isError, result } = getResultOrError(
        response.data?.toggleWorkspaceInvite,
        response.error
      );
      setIsMutationError(isError);
      if (isError) {
        // If the user's trying to disable and it fails, issue an error toast.
        if (!enable && oldIsInviteEnabled) {
          showToast(
            <Toast type="error" message="Error disabling link, try again." />
          );
        }
        // If the user's trying to enable and it fails, issue an error toast.
        if (enable && !oldIsInviteEnabled) {
          showToast(
            <Toast type="error" message="Error generating link, try again." />
          );
        }
        setWorkspaceInviteCode(null);
        setIsInviteEnabled(oldIsInviteEnabled);
        // TODO: it is possible that this does not update the toggle button
        // as expected. We should test this and fix it if necessary.
        // Issue is that Toggle maintains its own state.
      } else {
        setWorkspaceInviteCode(result.workspace.inviteCode);
      }
    },
    [isInviteEnabled, toggleLinkSharing, workspaceId, context, showToast]
  );

  return (
    <div className="flex flex-col">
      {clickedMember ? (
        <>
          <div className="flex justify-between">
            <TertiaryButton
              onClick={() => {
                setClickedMember(undefined);
              }}
            >
              <div className="flex space-x-[1ch] items-center">
                <Icon type="left" size="md" />
                <span>Back</span>
              </div>
            </TertiaryButton>
          </div>
          <div className="h-f4" />
          <MemberAccessRevoker
            row={clickedMember}
            onDelete={() => {
              setClickedMember(undefined);
            }}
          />
        </>
      ) : (
        <>
          <div className="flex justify-between pb-f4">
            <span>Members</span>
            <div className="flex flex-row space-x-[1ch]">
              <div className="flex space-x-[1ch]">
                <span className="text-main-500">Link Sharing</span>
                <div className="inline-block">
                  <Toggle
                    onLeftClick={() => onChange(false)}
                    onRightClick={() => onChange(true)}
                    leftLabel={loadingOrDefaultText}
                    rightLabel="On"
                    buttonPadding="p-f0"
                    buttonHeight="h-f2"
                    initialActiveButton={isInviteEnabled ? 'right' : 'left'}
                  />
                </div>
              </div>
              <div className="flex h-f2">{inviteCodeComponent}</div>
            </div>
          </div>

          <MembersListTable
            workspaceId={workspaceId}
            onRowClick={setClickedMember}
          />
        </>
      )}
    </div>
  );
};
