import { FragmentDashboardEnvSchema } from '../scripts/envType';

const parseResult = FragmentDashboardEnvSchema.safeParse(
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.env ?? JSON.parse(import.meta.env.VITE_APP_ENV)
);

if (!parseResult.success) {
  throw new Error(
    [
      'Unable to parse the .env file.',
      'This is most probably because you have an outdated .env file.',
      'Try running "yarn gen-env" to see if it resolves.',
      import.meta.env.MODE === 'development' &&
        'Visit https://www.notion.so/fragmentdev/UI-Development-c8112d490a5c4d1fb6610d3aa5772d2e?pvs=4#15f9fb7ad3a68015abd8f89ab0702924 to learn how to do this.',
      `Error: ${parseResult.error.message}`,
    ]
      .filter((p) => !!p)
      .join('\n')
  );
}

export const FragmentEnv = parseResult.data;
