import clsx from 'clsx';
import React, { type FC, useCallback, useId, useMemo, useState } from 'react';
import { useWorkspace } from 'wrappers/WorkspaceProvider';

import { PrimaryButtonV2 } from '@fragment/ui/src/components/Button/PrimaryButtonV2/PrimaryButtonV2';
import { SecondaryButtonV2 } from '@fragment/ui/src/components/Button/SecondaryButtonV2/SecondaryButtonV2';
import { TextInputCopy } from '@fragment/ui/src/components/TextInputCopy/TextInputCopy';
import { useInlineCopy } from '@fragment/ui/src/hooks/useInlineCopy';

type ApiClientCreatedProps = {
  clientId: string;
  clientSecret: string;
  clientName: string;
  onDone: VoidFunction;
};

export const ApiClientCreatedView: FC<ApiClientCreatedProps> = ({
  clientId,
  clientSecret,
  clientName,
  onDone,
}) => {
  const [userDidCopy, setUserDidCopy] = useState(false);
  const namespace = useId();
  const OAuthDomainId = `${namespace}-OAuthDomain`;
  const OAuthScope = `${namespace}-OAuthScope`;
  const clientFieldId = `${namespace}-client-id`;
  const clientSecretFieldId = `${namespace}-client-secret`;
  const apiURLFieldId = `${namespace}-api-url`;
  const { workspace: activeWorkspaceFromContext } = useWorkspace();
  const data = useMemo(
    () => ({
      clientId,
      clientSecret,
      apiUrl: activeWorkspaceFromContext?.pod.customerApiUrl ?? '',
      oauthUrl: `https://${activeWorkspaceFromContext?.pod.cognitoAuthDomain}/oauth2/token`,
      oauthScope: activeWorkspaceFromContext?.pod.customerApiOAuthScope ?? '',
    }),
    [clientId, clientSecret, activeWorkspaceFromContext]
  );
  const { isCopied, handler } = useInlineCopy({
    textToCopy: JSON.stringify(data, null, 2),
    onCopyError: () => {
      setUserDidCopy(false);
    },
  });

  const onCopyAllClick = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(
    (evt) => {
      handler(evt);
      setUserDidCopy(true);
    },
    [handler]
  );

  return (
    <div className={clsx('space-y-f4', 'max-w-full ')}>
      <div className="space-y-f2">
        <TextInputCopy
          size="md"
          id={clientFieldId}
          padding="py-f0 px-[0.5ch]"
          value={clientId}
          width="w-full"
          label="Client ID"
        />
        <div>
          <TextInputCopy
            size="md"
            id={clientSecretFieldId}
            value={clientSecret}
            onClick={() => setUserDidCopy(true)}
            width="w-full"
            label="Client Secret"
            padding="py-f0 px-[0.5ch]"
          />
          <div className="italic">This key will not be shown again.</div>
        </div>
        {activeWorkspaceFromContext?.pod && (
          <TextInputCopy
            size="md"
            id={apiURLFieldId}
            width="w-full"
            label="API URL"
            value={activeWorkspaceFromContext.pod.customerApiUrl}
            padding="py-f0 px-[0.5ch]"
          />
        )}
        {activeWorkspaceFromContext?.pod && (
          <TextInputCopy
            size="md"
            id={OAuthDomainId}
            value={`https://${activeWorkspaceFromContext.pod.cognitoAuthDomain}/oauth2/token`}
            padding="py-f0 px-[0.5ch]"
            width="w-full"
            label="OAuth URL"
          />
        )}
        {activeWorkspaceFromContext?.pod && (
          <TextInputCopy
            size="md"
            id={OAuthScope}
            padding="py-f0 px-[0.5ch]"
            value={activeWorkspaceFromContext.pod.customerApiOAuthScope}
            width="w-full"
            label="OAuth Scope"
          />
        )}
      </div>
      <div className="flex flex-row gap-x-f1">
        <SecondaryButtonV2 onClick={onCopyAllClick} data-testid="copy-json">
          {isCopied ? 'Copy JSON✓' : 'Copy JSON⎘'}
        </SecondaryButtonV2>
        <PrimaryButtonV2 onClick={onDone} disabled={!userDidCopy}>
          Done
        </PrimaryButtonV2>
      </div>
    </div>
  );
};
