/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/destructuring-assignment */
import clsx from 'clsx';
import React from 'react';

import type { BaseRowType, BaseTableProps } from './helpers';
import type { LoadMoreRowProps } from './Row';
import { Header } from './Header';
import { EmptyTableRow, LoadMoreRow, Row } from './Row';

export type TableProps<T extends BaseRowType> = (
  | BaseTableProps<T>
  | (Omit<BaseTableProps<T>, 'hasNextPage'> & {
      hasNextPage: true;
    } & LoadMoreRowProps)
) & { disableRowHover?: boolean; onRowClick?: (row: T) => void };

export const Table = <T extends BaseRowType>(props: TableProps<T>) => {
  const {
    data,
    selected,
    schema,
    emptyValue,
    hasNextPage,
    'data-testid': dataTestId,
    disableRowHover,
    onRowClick,
    borderedRows,
    textSize = 'md',
  } = props;

  return (
    <div
      className={clsx(textSize === 'sm' ? '-mx-f2' : 'mx-f0', 'min-h-f0')}
      role="table"
      aria-label="Table"
      data-testid={dataTestId}
    >
      <Header schema={schema} textSize={textSize} />
      {/* the table height should be the left overspace after subtracting padding and other */}
      {/* component heights in the main pane */}
      {data.length > 0 && (
        <div className="overflow-auto h-[calc(100%_-_27px)]">
          {data.map((row, idx) =>
            React.createElement(
              onRowClick ? 'button' : 'div',
              {
                type: onRowClick ? 'button' : undefined,
                className: 'group w-full',
                'data-idx': String(idx),
                'data-testid': row.testId,
                key: String(row.id),
                onClick: onRowClick ? () => onRowClick(row) : undefined,
              },
              <Row
                textSize={textSize}
                index={idx}
                value={row}
                selected={selected?.id === row.id}
                schema={schema}
                bordered={borderedRows}
                disableHover={disableRowHover}
                isLast={idx === data.length - 1}
              />
            )
          )}
          {hasNextPage && (
            // can't destructure because props are only present when hasNextPage is true
            <LoadMoreRow
              textSize={textSize}
              isLoading={props.isLoading}
              loadMore={props.loadMore}
              bordered={borderedRows}
            />
          )}
        </div>
      )}
      {/* If no rows, optionally display a sentinel row to avoid a weird UX */}
      {/* Use data.length, not !data for array-ish objects (used in stories) */}
      {data.length === 0 && emptyValue && (
        <EmptyTableRow
          value={emptyValue}
          schema={schema}
          textSize={textSize}
          bordered={borderedRows}
        />
      )}
    </div>
  );
};
