import React from 'react';

import { useLoadingText } from '../../hooks/LoadingText';
import { useLoopingTicker } from '../../hooks/useTicker';

type Props = {
  text: string;
  color?: string;
};

export const LoadingText = ({ text, color }: Props) => {
  const displayValue = useLoadingText(text);
  const twColor = color ?? 'text-main-500';
  return (
    <span
      style={{
        fontVariantLigatures: 'no-contextual',
      }}
    >
      <span className={twColor}>{displayValue}</span>
    </span>
  );
};

export const LoadingIndicator = () => {
  const frames = ['/', '-', '\\'];
  const tick = useLoopingTicker(300, frames.length);
  return <span>{frames[tick % frames.length]}</span>;
};
